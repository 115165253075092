<template>
  <div v-if="Object.keys(requisition).length !== 0">
    <div class="">
      <b-modal id="item-requisition-status-modal" title="Items Requisition Request Status" size="xl" hide-footer>
        <div class="modal-content bg-white rounded">
          <div class="card">
            <div class="card-body" style="white-space: pre;">
              <a-steps progress-dot  :current="current" size="small">
                <a-step title="Product Request" :description="requisition.requisition_statuses.filter(e => e.status === 'STORE_IN_CHARGE_PENDING').length > 0 ? customDate(requisition.requisition_statuses.find(e => e.status === 'STORE_IN_CHARGE_PENDING').created_at) + '\n' + requisition.requisition_creator_user.name : ''" />
                <a-step title="Main Warehouse"  :description="requisition.requisition_statuses.filter(e => e.status === 'STORE_IN_CHARGE_APPROVED').length > 0 ? customDate(requisition.requisition_statuses.find(e => e.status === 'STORE_IN_CHARGE_APPROVED').created_at)  + '\n' + requisition.requisition_statuses.find(e => e.status === 'STORE_IN_CHARGE_APPROVED').user.name : ''"  />
                <a-step title="Admin" :description="requisition.requisition_statuses.filter(e => e.status === 'ADMIN_APPROVED').length > 0 ? customDate(requisition.requisition_statuses.find(e => e.status === 'ADMIN_APPROVED').created_at) + '\n' + requisition.requisition_statuses.find(e => e.status === 'ADMIN_APPROVED').user.name : ''" />
                <a-step title="Product Transfered"  :description="requisition.requisition_statuses.filter(e => e.status === 'REQUISITION_TRANSFER_COMPLETED').length > 0 ? customDate(requisition.requisition_statuses.find(e => e.status === 'REQUISITION_TRANSFER_COMPLETED').created_at) + '\n' + requisition.requisition_statuses.find(e => e.status === 'REQUISITION_TRANSFER_COMPLETED').user.name : ''" />
                <a-step title="Tejgaon Dispatch" v-if="requisition.hub.hub_zone_id === 2" :description="requisition.requisition_statuses.filter(e => e.status === 'REQUISITION_TRANSFER_FROM_TEJGAON_COMPLETED').length > 0 ? customDate(requisition.requisition_statuses.find(e => e.status === 'REQUISITION_TRANSFER_FROM_TEJGAON_COMPLETED').created_at) + '\n' + requisition.requisition_statuses.find(e => e.status === 'REQUISITION_TRANSFER_FROM_TEJGAON_COMPLETED').user.name  : ''" />
                <a-step title="Hub Received"  :description="requisition.requisition_statuses.filter(e => e.status === 'REQUISITION_TRANSFER_RECEIVED').length > 0 ? customDate(requisition.requisition_statuses.find(e => e.status === 'REQUISITION_TRANSFER_RECEIVED').created_at) + '\n' + requisition.requisition_statuses.find(e => e.status === 'REQUISITION_TRANSFER_RECEIVED').user.name  : ''" />
              </a-steps>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'status',
  props: ['current', 'requisition'],
  data() {
    return {}
  },
  methods: {
    customDate(date) {
      return moment(date).format('LLL')
    },
  },
}
</script>

<style scoped>
.ant-steps-item-description {
  margin-top: -5px !important;
  white-space: pre;
}
</style>
